// Shrinking Header
var headerObserver = new IntersectionObserver(function(entries) {
  //console.log(entries[0]);
  //console.log(document.querySelector(".site-header-top").offsetTop - window.scrollY);
  // no intersection with screen
  if(entries[0].intersectionRatio === 0)
    document.querySelector(".site-navbar").classList.add("reduced");
  // fully intersects with screen
  else if(entries[0].intersectionRatio === 1)
    document.querySelector(".site-navbar").classList.remove("reduced");
}, { threshold: [0,1] });
headerObserver.observe(document.querySelector(".site-scroll__navbar"));