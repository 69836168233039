import Splide from '@splidejs/splide'
const inNeosBackend = window.name == 'neos-content-main';

// const splideSettings = {
Splide.defaults = {
  type: 'loop',
  arrowPath: 'M38.5,20L1.5,20M20,38.5L1.5,20L20,1.5',
  i18n:  {
    de: {
      prev: 'Vorheriges Slide',
      next: 'Nächstes Slide',
      first: 'Gehe zum ersten Slide',
      last: 'Gehe zum letzten Slide',
      slideX: 'Gehe zu Slide %s',
      pageX: 'Gehe zu Seite %s',
      play: 'Starte Autoplay',
      pause: 'Pausiere Autoplay',
      carousel: 'Karusell',
      select: 'Wählen Sie ein Slide zum Anzeigen',
      slide: 'Slide',
      slideLabel: '%s von %s'
    }
  }
}
const sliderElements = document.querySelectorAll('.cm-slider')
let splides = []
// window.splides = splides

function getFirstNode(nodeList) {
  return [...nodeList].filter((node) => node.tagName == 'LI')[0];
}

function getIndexOfElement(element) {
  // console.log('Children:', element.parentElement.children)
  // console.log('Element Index:', Array.from(element.parentElement.children).indexOf(element))
  return Array.from(element.parentElement.children).indexOf(element);
}

function splideInit(el) {
  if(!el) return
  // console.log('Init Splide:', typeof el, el)
  let splide = new Splide(el)
  splides.push(splide)
  // console.log('Initialized Splide:', splide)
}

sliderElements.forEach((splideElement, i) => {
  splideInit(splideElement)
})

// if(inNeosBackend) {
//   splides.forEach(splide => {
//     splide.on('mounted', function () {
//       console.log('Mount Splide:', splide)
//       const observeTarget = splide.root.querySelector('.splide__list');
//       const observer = new MutationObserver((mutations) => {
//           mutations.forEach((mutation) => {
//               const addedNode = getFirstNode(mutation.addedNodes);
//               const removedNode = getFirstNode(mutation.removedNodes);
//               if (addedNode || removedNode) {
//                   console.log('Refreshing instance');
//                   splide.refresh();
//               }
//               if (addedNode) {
//                   // Scroll to the new slide
//                   splide.go(getIndexOfElement(addedNode));
//               }
//           });
//       });
//       observer.observe(observeTarget, { childList: true });

//       // Go to the slide if it gets selceted in the node tree
//       document.addEventListener('Neos.NodeSelected', (event) => {
//           console.log('NodeSelected:', event)
//           const element = event.detail.element;
//           if (!element.classList.contains('splide__slide')) {
//               return;
//           }
//           splide.go(getIndexOfElement(element));
//       });
//     })
//   })
// }

if(inNeosBackend) {
  document.addEventListener('Neos.NodeCreated', e => {
    // const node = e.detail.node;
    // console.log('Node created:', e)
    if(e.detail.element.classList.contains('cm-slider')) {
      // console.log('Slider x created.')
      // splideInit(e.detail.element)
      e.detail.element.innerText = 'TEST!!'
    }
  }, false);

  // document.addEventListener('Neos.NodeSelected', (event) => {
  //     console.log('NodeSelected:', event)
  //     const element = event.detail.element;
  //     if (!element.classList.contains('splide__slide')) {
  //         return;
  //     }
  //     console.log(getIndexOfElement(element))
  //     // splide.go(getIndexOfElement(element));
  // });
}

// mount all splides
splides.forEach(splide => {
  splide.mount()
})