// // Fetch all the details element.
// const details = document.querySelectorAll("details");

// // Add the onclick listeners.
// details.forEach((detail) => {
//   detail.addEventListener("toggle", () => {
//     if (detail.open) setTargetDetail(detail);
//   });
// });

// // Close all the details that are not targetDetail.
// function setTargetDetail(targetDetail) {
//   details.forEach((detail) => {
//     if (detail !== targetDetail) {
//       detail.open = false;
//     }
//   });
// }

import { Accordion } from '@ryze-digital/accordion'
if(document.querySelector('[data-accordion]')) {
  new Accordion().init()
}
